import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";
import { Image } from "primereact/image";
// Common
import { successToast, failureToast } from "../Common/CommonFunctions";

import { KettleBillingTypeOptions, rentleOptions, subScriptionOptions, paymentTermOptions, paymentTypeOptions, extentionPeriodOptions, entityOptions } from "./Constants";

import { KettleProducts } from "./KettleProducts";

import ApiServices from "../../service/api/ApiServices";
import { InputNumber } from "primereact/inputnumber";
const apiServices = new ApiServices()
// LocalStorage
const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

// ===============================================================================================
// ================================= Kettle User Form ============================================
// ===============================================================================================

export const KettleUserForm = (props) => {

    const appOptions = {
        headers: {
            "Authorization": props.verifiedUserData?.token,
            "Content-type": "application/json"
        }
    };


    const payloadData = {
        "country_code": "+91",
        "prefix": "Mr.",
        "name": '',
        "last_name": '',
        'email': '',
        "facility_name": "",
        "shipping_address": "",
        "shipping_state": "",
        "shipping_city": '',
        "shipping_pincode": "",
        "business_name": '',
        "business_address": '',
        "business_state": '',
        "business_city": "",
        "business_pincode": '',
        "business_registration": '',
        "gst": '',
        "fsaai": '',
        "billing_type": "Cupwise",
        "subscription_type": "RENT",
        "packet_cost": 500,
        "cup_price": 10,
        "speciality_cost": 10,
        "is_power": false,
        "is_space": false,
        "is_water": false,
        // "business_lat": '',
        // "business_lng": '',
        // "address_lat": '',
        // "address_lng": '',
        "user_id": '',
        "user_image": "",
        "proof_front": "",
        "proof_back": "",
        "proof_store": "",

        "rental_plan": "monthly",
        "billing_type": "Cupwise",
        "signing_authority_prefix": "",
        "signing_authority_person_name": "",
        "signing_authority_person_last_name": "",
        "signing_authority_designation": "",
        "signing_authority_mobile": "",
        "signing_authority_email": "",
        "refundable_deposit": 0,
        "legal_entity_name": entityOptions[0].name,
        "payment_terms": {
            "extPeriod": 7   
        }
    }

    const countryOption = [
        { name: 'India' }
    ]

    const prefixOptions = [
        { name: 'Mr.' },
        { name: 'Mrs.' }
    ]

    // const cityData = [
    //     { name : 'Mumbai'},
    //     { name : 'Pune'},
    //     { name : 'Goa'},
    //     { name : 'Kolkata'},
    //     { name : 'Delhi'},
    //     { name : 'Hyderabad'},
    //     { name : 'Banglore'},
    // ]

    const [openKettleFormDialog, setOpenKettleFormDialog] = useState(false)
    const [isProceeded, setIsProceeded] = useState(false);
    const [proceedPayload, setProceedPayload] = useState(payloadData);
    // const [selectedCity,setSelectedCity] = useState(null);
    const [isRegistered, setIsRegistered] = useState(false);
    const [registeredUser, setRegisteredUser] = useState(null);
    const [bussinessPincode, setBussinessPincode] = useState('');
    const [states, setStates] = useState(null)
    const [selectedStates, setSelectedStates] = useState(null)
    const [cities, setCities] = useState(null)
    const [selectedCity, setSelectedCity] = useState(null)
    const [shippingPincode, setShippingPincode] = useState('');
    const [selectedPrefix, setSelectedPrefix] = useState(prefixOptions[0]);
    const [selectedBStates, setSelectedBStates] = useState(null);
    const [bussCities, setBussCities] = useState(null);
    const [selectedBCity, setSelectedBCity] = useState(null);
    const [isClicked, setIsClicked] = useState(false);
    // const [packetRateAfterGST, setPacketRateAfterGST] = useState(null);
    // const [cupRate, setCupRate] = useState(null);
    const [selectedBillingType, setSelectedBillingType] = useState({ name: "Cup Wise", code: "Cupwise" });
    const [selectedSubOption, setSelectedSubOptions] = useState({ name: 'Maintenance Charge', code: 'RENT' })
    const [selectedRentleOption, setSelectedRentleOption] = useState({ name: 'Monthly Maintenance Charge', code: 'monthly' });

    const [signingCheck, setSigningCheck] = useState(false);
    const [selectedPrefixForSign, setSelectedPrefixForSign] = useState({ name: 'Mr.' })
    const [selectedMode, setSelectedMode] = useState({ name: "Prepaid", code: "PREPAID" });
    const [seletedExtPeriod, setSelectedExtPeriod] = useState(7);
    const [selectedPaymentType, setSelectedPaymentType] = useState({ name: "Direct Bank Account", code: "Direct Bank Account" });
    const [selectedTypeOfEntity, setSelectedTypeOfEntity] = useState(entityOptions[0])




    const toast = useRef(null);


    const getDefaultData = (data) => {
        console.log("getDefaultData", data);
        setProceedPayload({
            ...proceedPayload,
            contact: data?.contact,
            payment_terms: data?.ctvm_payment_terms
        })

    }   


    useEffect(() => {
        setOpenKettleFormDialog(props.openKettleFormDialog);
    }, [props.openKettleFormDialog])

    useEffect(() => {
        getDefaultData(props.verifiedUserData);
    }, [props.verifiedUserData])

    useEffect(() => {
        getInitiate()
    }, [])


    useEffect(()=> {
        console.log("proceedPayload", proceedPayload);
    }, [proceedPayload])

    const getInitiate = async () => {
        const states = await apiServices.getState({ country_id: '101' })
        setStates(states?.data)
    }

    const getCity = async (data) => {
        console.log(data);
        const city = await apiServices.getCity({ state_id: data?.id })
        setCities(city?.data)
    }

    const getBussCity = async (data) => {
        console.log(data);
        const city = await apiServices.getCity({ state_id: data?.id })
        setBussCities(city?.data)
    }

    const proceedKettleOrder = () => {
        setIsProceeded(true)
        setOpenKettleFormDialog(false)
    }

    const changeKettle = (e) => {
        const name = e.target.name;

        if (name == 'name' || name == 'last_name' || name == 'shipping_address' || name == 'business_name' || name == 'business_address' || name == 'business_registration'
            || name == 'gst' || name == 'fsaai' || name == 'business_pincode' || name == 'cup_cost' || name == 'packet_cost' || name == 'latitude' || name == 'longitude' || name == 'rent_amount' || name == 'cup_price' || name == 'signing_authority_person_name' || name == 'signing_authority_person_last_name' || name == 'signing_authority_designation' || name == 'signing_authority_mobile' || name == 'signing_authority_email' || name == 'facility_name' || name == 'email' || name == 'pan' || name == 'speciality_cost' || name == 'refundable_deposit') {
            setProceedPayload({ ...proceedPayload, [name]: e.target.value })
        }

        if (name == 'billing_type') {
            setSelectedBillingType(e.target.value);
            setProceedPayload({ ...proceedPayload, [name]: e.target.value?.code })
        }

        if (name == 'rental_plan') {
            // console.log(e.target.value)
            setSelectedRentleOption(e.value)
            setProceedPayload({ ...proceedPayload, [e.target.name]: e.target.value?.code })
        }

        if (name == 'subscription_type') {
            console.log(e.target.value);
            setSelectedSubOptions(e.target.value);
            setProceedPayload({ ...proceedPayload, [name]: e.target.value?.code })
        }

        if (name == 'prefix') {
            setSelectedPrefix(e.value)
            setProceedPayload({ ...proceedPayload, [name]: e.value.name })
        }

        if (name == 'shipping_state') {
            // console.log(e.value);
            setSelectedStates(e.value)
            setProceedPayload({ ...proceedPayload, [name]: e.value.name })
            getCity(e.value)
        }

        if (name == 'business_state') {
            // console.log(e.value);
            setSelectedBStates(e.value)
            setProceedPayload({ ...proceedPayload, [name]: e.value.name })
            getBussCity(e.value)
        }

        if (name == 'business_city') {
            setSelectedBCity(e.value)
            setProceedPayload({ ...proceedPayload, [name]: e.value.name })
        }

        if (name == 'business_pincode') {
            const isValid = isNaN(Number(e.target.value));
            if (!isValid) {
                const trimmed = e.target.value?.trim();
                setBussinessPincode(trimmed)
                setProceedPayload({ ...proceedPayload, [name]: trimmed })
            }
        }

        if (name == 'shipping_pincode') {
            const isValid = isNaN(Number(e.target.value));
            if (!isValid) {
                const trimmed = e.target.value?.trim();
                setShippingPincode(trimmed)
                setProceedPayload({ ...proceedPayload, [name]: trimmed })
            }
        }

        if (name == 'shipping_city') {
            setSelectedCity(e.value)
            setProceedPayload({ ...proceedPayload, [name]: e.value.name })
        }

        if (name == 'is_power' || name == 'is_space' || name == 'is_water' || name == 'is_earthing') {
            setProceedPayload({ ...proceedPayload, [name]: e.checked })
        }


        if (name == 'signing_authority_prefix') {
            setSelectedPrefixForSign(e.target.value);
            setProceedPayload({ ...proceedPayload, [name]: e.target.value?.name })
        }


        if (name == 'minAmtPercent') {
            setProceedPayload({ ...proceedPayload, payment_terms: { ...proceedPayload?.payment_terms, [e.target.name]: e.target.value } })
        }

        if (name == 'extPeriod') {
            setSelectedExtPeriod(e.target.value);
            setProceedPayload({ ...proceedPayload, payment_terms: { ...proceedPayload?.payment_terms, [e.target.name]: e.target.value } })
        }

        if (name == 'mode') {
            setSelectedMode(e.target.value)
            setProceedPayload({ ...proceedPayload, payment_terms: { ...proceedPayload?.payment_terms, [name]: e.target?.value?.code } })
        }

        if (name == 'prepaid_payment_type') {
            setSelectedPaymentType(e.target.value);
            setProceedPayload({ ...proceedPayload, payment_terms: { ...proceedPayload?.payment_terms, [name]: e.target?.value?.code } })
        }

        if (name == 'legal_entity_name') {
            setSelectedTypeOfEntity(e.target.value)
            setProceedPayload({ ...proceedPayload, [name]: e.target.value.name })
        }

        // if (name == 'cup_price') {

        //     if (e.target.value) {
        //         const calculatedGST = Number(e.target.value) * 18 / 100;
        //         const totalGstIncluded = Number(calculatedGST) + Number(e.target.value);
        //         setPacketRateAfterGST(totalGstIncluded.toFixed(2))
        //         const cupRate = Number(e.target.value) / 50;
        //         setCupRate(cupRate.toFixed(2));
        //         setProceedPayload({ ...proceedPayload, [name]: Number(cupRate.toFixed(2)) })

        //     } else {
        //         setPacketRateAfterGST(null)
        //         setCupRate(null)
        //     }

        // }
    }


    const changeFiles = (e) => {


        if (e.target.name == 'user_image') {
            const image_as_files = e.target.files;
            setProceedPayload({ ...proceedPayload, [e.target.name]: image_as_files?.length > 0 ? [image_as_files] : "" })
            image_as_files?.length > 0 ? document.getElementById('keProfile').src = window?.URL.createObjectURL(e.target.files[0]) : document.getElementById('keProfile').src = 'assets/demo/images/user/default-user.png';
        }

        if (e.target.name == 'proof_front') {
            const image_as_files = e.target.files;
            setProceedPayload({ ...proceedPayload, [e.target.name]: image_as_files?.length > 0 ? [image_as_files] : "" })
            image_as_files?.length > 0 ? document.getElementById('addhar-Front').src = window?.URL.createObjectURL(e.target.files[0]) : document.getElementById('addhar-Front').src = 'assets/demo/images/user/id-front.png';
        }

        if (e.target.name == 'proof_back') {
            const image_as_files = e.target.files;
            setProceedPayload({ ...proceedPayload, [e.target.name]: image_as_files?.length > 0 ? [image_as_files] : "" })
            image_as_files?.length > 0 ? document.getElementById('addhar-back').src = window?.URL.createObjectURL(e.target.files[0]) : document.getElementById('addhar-back').src = 'assets/demo/images/user/id-back.png';
        }

        if (e.target.name == 'proof_store') {
            const image_as_files = e.target.files;
            console.log(image_as_files?.length);
            setProceedPayload({ ...proceedPayload, [e.target.name]: image_as_files?.length > 0 ? [image_as_files] : "" })
            image_as_files?.length > 0 ? document.getElementById('store-photo').src = window?.URL.createObjectURL(e.target.files[0]) : document.getElementById('store-photo').src = 'assets/demo/images/user/default-store.png';
        }
    }

    const submitKettleForm = async (e) => {

        setIsClicked(true)

        e.preventDefault()

        const payloadData = {
            ...proceedPayload,
            // "business_lat": props.latLong?.currentLatitude || '18.6013908',
            // "business_lng": props.latLong?.currentLongitude || '74.0058594',
            // "address_lat": props.latLong?.currentLatitude || '18.6013908',
            // "address_lng": props.latLong?.currentLongitude || '74.0058594',
            "user_id": props.verifiedUserData?.id || initialValue?.app_data?.id,
            "contact": props.verifiedUserData?.contact
        };

        console.log(payloadData);

        const payload = new FormData();
        Object.entries(payloadData).forEach(([key, value]) => { payload.append(key, value) });

        if (payloadData?.proof_front) {

            payloadData?.proof_front?.forEach((x, i) => {
                for (let index = 0; index < x.length; index++) {
                    payload.append("proof_front", x[index], x[index]?.name);
                }
            })
        }

        if (payloadData?.proof_back) {

            payloadData?.proof_back?.forEach((x, i) => {
                for (let index = 0; index < x.length; index++) {
                    payload.append("proof_back", x[index], x[index]?.name);
                }
            })
        }

        if (payloadData?.proof_store) {

            payloadData?.proof_store?.forEach((x, i) => {
                for (let index = 0; index < x.length; index++) {
                    payload.append("proof_store", x[index], x[index]?.name);
                }
            })
        }

        if (payloadData?.user_image) {

            payloadData?.user_image?.forEach((x, i) => {
                for (let index = 0; index < x.length; index++) {
                    payload.append("user_image", x[index], x[index]?.name);
                }
            })
        }

        console.log(payload);

        const response = await apiServices.saveKettleStore(payload);
        if (response?.data?.response_code == 1) {
            successToast(toast, 'User Registered Successfully');
            onSuccess(response?.data?.response_obj)
            hideKettleDialog()
        } else {
            failureToast(toast, 'Failed to Add');
            setIsClicked(false)
        }
    }

    const changeSigned = (e) => {
        setSigningCheck(e.checked)
        if (e.checked) {
            setSelectedPrefixForSign(selectedPrefix)
            setProceedPayload({
                ...proceedPayload,
                'signing_authority_prefix': proceedPayload?.prefix,
                'signing_authority_person_name': proceedPayload?.name ? proceedPayload?.name : '',
                'signing_authority_person_last_name': proceedPayload?.last_name ? proceedPayload?.last_name : '',
                'signing_authority_mobile': proceedPayload?.contact ? proceedPayload?.contact.toString(): '',
                'signing_authority_email': proceedPayload?.email ? proceedPayload?.email : ''
            })
        } else {
            setSelectedPrefixForSign({ name: 'Mr.' })
            setProceedPayload({
                ...proceedPayload,
                'signing_authority_prefix': 'Mr.',
                'signing_authority_person_name': '',
                'signing_authority_person_last_name': '',
                'signing_authority_mobile': '',
                'signing_authority_email': ''
            })
        }
    }

    const onSuccess = (data) => {
        console.log("onSuccess", data);
        setIsRegistered(true)
        setOpenKettleFormDialog(false)
        props.onSubmitKettleReg()
        setRegisteredUser(data)
    }

    const hideKettleDialog = () => {
        setOpenKettleFormDialog(false)
        setSelectedCity(null)
        setBussinessPincode('')
        setProceedPayload(payloadData)
        setSelectedStates(null)
        setCities(null)
        setShippingPincode('');
        setSelectedPrefix(prefixOptions[0]);
        setSelectedBStates(null);
        setBussCities(null);
        setSelectedBCity(null);
        setIsClicked(false);
        // setPacketRateAfterGST(null);
        // setCupRate(null)
    }

    const selectionDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={hideKettleDialog} />
            {
                (proceedPayload?.user_image?.length > 0 && proceedPayload?.proof_front?.length > 0 && proceedPayload?.proof_back?.length > 0 && proceedPayload?.proof_store?.length > 0) &&
                <Button label="Submit" icon={isClicked ? 'pi pi-spin pi-spinner' : 'pi pi-check'} type='submit' form="kettle-form" className="p-button-success p-mr-2 p-mb-2" disabled={isClicked} />
            }
        </>
    );

    return (
        <>
            <Toast ref={toast} position="center" />

            {
                !isRegistered &&
                <Dialog visible={openKettleFormDialog} style={{ width: '1200px' }} header={`Tapri Kettle Order (+91 ${props.verifiedUserData?.contact || initialValue?.cno})`} modal footer={selectionDialogFooter} onHide={hideKettleDialog} className='p-fluid' blockScroll={true}>
                    <form onSubmit={submitKettleForm} encType="multipart/form-data" id="kettle-form">
                        <h5>Personal Information</h5>
                        <div className="p-grid">

                            <div className="p-col-6 p-md-1">
                                <div className="p-field">
                                    <label htmlFor="prefix">Prefix</label>
                                    <Dropdown id="prefix" name="prefix" value={selectedPrefix} options={prefixOptions} optionLabel="name" placeholder="Select Prefix" onChange={changeKettle} />
                                </div>
                            </div>
                            <div className="p-col-4 p-md-3">
                                <div className="p-field">
                                    <label htmlFor="name">First Name</label>
                                    <InputText id="name" name="name" placeholder="First Name" onChange={changeKettle} required />
                                </div>
                            </div>
                            <div className="p-col-4 p-md-3">
                                <div className="p-field">
                                    <label htmlFor="last_name">Last Name</label>
                                    <InputText id="last_name" name="last_name" placeholder="Last Name" onChange={changeKettle} required />
                                </div>
                            </div>

                            <div className="p-col-4 p-md-3">
                                <div className="p-field">
                                    <label htmlFor="email">Email</label>
                                    <InputText id="email" name="email" placeholder="Email" onChange={changeKettle} required />
                                </div>
                            </div>

                            <div className="p-col-4 p-md-2">
                                <center>
                                    <div className="user-detail-wrapper">
                                        <div className="user-detail-content">
                                            <label htmlFor="kettle-profile">
                                                <img id="keProfile" src={'assets/demo/images/user/default-user.png'} alt="Profile" className="kettle-profile-image" />
                                            </label>
                                            <InputText id="kettle-profile" type="file" name="user_image" accept="image/x-png,image/gif,image/jpeg" onChange={changeFiles} style={{ display: 'none' }} />
                                        </div>
                                    </div>
                                </center>
                            </div>

                        </div>

                        <hr></hr>

                        <h5>SHIPPING INFORMATION</h5>
                        <div className="p-grid">

                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="add">Facility Name</label>
                                    <InputText id="add" name="facility_name" placeholder="Facility Name" onChange={changeKettle} required />
                                </div>
                            </div>

                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="add">Shipping Address</label>
                                    <InputText id="add" name="shipping_address" placeholder="Address" onChange={changeKettle} required />
                                </div>
                            </div>
                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="country">Country</label>
                                    <Dropdown id="country" name="country" value={countryOption[0]} options={countryOption} optionLabel="name" placeholder="Select Country" disabled />
                                </div>
                            </div>
                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="state">State</label>
                                    <Dropdown id="state" name="shipping_state" value={selectedStates} onChange={changeKettle} options={states} optionLabel="name" placeholder="Select State" filter required />
                                </div>
                            </div>
                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="city">City</label>
                                    <Dropdown id="city" name="shipping_city" value={selectedCity} onChange={changeKettle} options={cities} optionLabel="name" placeholder="Select City" filter required />
                                </div>
                            </div>

                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="pincode">Pincode</label>
                                    <InputText id="pincode" name="shipping_pincode" placeholder="Pincode" minLength={6} maxLength={6} value={shippingPincode ? shippingPincode : ''} onChange={changeKettle} required />
                                </div>
                            </div>

                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="latitude">Latitude</label>
                                    <InputText type="text" id="latitude" name="latitude" placeholder="Latitude" onChange={changeKettle} required />
                                </div>
                            </div>

                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="longitude">Longitude</label>
                                    <InputText type="text" id="longitude" name="longitude" placeholder="Longitude" onChange={changeKettle} required />
                                </div>
                            </div>


                        </div>
                        <hr></hr>
                        <h5>BUSINESS INFORMATION</h5>
                        <div className="p-grid">
                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="bname">Bussiness Name</label>
                                    <InputText id="bname" name="business_name" placeholder="Bussiness Name" onChange={changeKettle} required />
                                </div>
                            </div>
                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="badd">Address</label>
                                    <InputText id="badd" name="business_address" placeholder="Address" onChange={changeKettle} required />
                                </div>
                            </div>
                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="state">State</label>
                                    <Dropdown id="state" name="business_state" value={selectedBStates} onChange={changeKettle} options={states} optionLabel="name" placeholder="Select State" filter required />
                                </div>
                            </div>

                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="city">City</label>
                                    <Dropdown id="city" name="business_city" value={selectedBCity} onChange={changeKettle} options={bussCities} optionLabel="name" placeholder="Select City" filter required />
                                </div>
                            </div>

                            {/*  */}

                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="pincode">Pincode</label>
                                    <InputText id="pincode" name="business_pincode" placeholder="Pincode" minLength={6} maxLength={6} value={bussinessPincode ? bussinessPincode : ''} onChange={changeKettle} required />
                                </div>
                            </div>
                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="bregNum">Business Registration Number</label>
                                    <InputText id="bregNum" name="business_registration" placeholder="Business Registration Number" onChange={changeKettle} />
                                </div>
                            </div>


                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="gstRegNum">GST Number <small className="p-error">(example : 18AABCU9603R1ZM) </small></label>
                                    <InputText id="gstRegNum" name="gst" placeholder="GST Registration Number" minLength={15} maxLength={15} pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$" onChange={changeKettle} />
                                </div>
                            </div>

                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="panCard">PAN Id <small className="p-error">(exmaple : ABCTY1234D)</small></label>
                                    <InputText id="panCard" name="pan" placeholder="Pan Card" pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}" onChange={changeKettle}
                                    />
                                </div>
                            </div>

                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="fssaiReg">FSSAI Registration</label>
                                    <InputText id="fssaiReg" name="fsaai" placeholder="FSSAI Registration" onChange={changeKettle} />
                                </div>
                            </div>




                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="billing_type">Billing Type</label>
                                    <Dropdown id="billing_type" name="billing_type" value={selectedBillingType} onChange={changeKettle} options={KettleBillingTypeOptions} optionLabel="name" placeholder="Select Billing Type" filter className="multiselect-custom" required />
                                </div>
                            </div>

                            {
                                selectedBillingType?.code == 'PacketWise' && <div className="p-col-4">
                                    <div className="p-field">
                                        <label htmlFor="packet_cost">Packet Rate (Excluding GST)</label>
                                        <InputText id="packet_cost" type="number" name="packet_cost" min={240} max={999} placeholder="Packet Rate (Excluding GST)" onChange={changeKettle} required />
                                    </div>
                                </div>
                            }
                            {
                                selectedBillingType?.code == 'Cupwise' && <div className="p-col-4">
                                    <div className="p-field">
                                        <label htmlFor="cup_price">Cup Cost (Excluding GST)</label>
                                        <InputText type="number" min={1} max={99} id="cup_price" onChange={changeKettle} name="cup_price" placeholder="Cup Cost (Excluding GST)" required />
                                    </div>
                                </div>
                            }

                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="speciality_cost">Speciality Cost (Excluding GST)</label>
                                    <InputText type="number" min={1} max={99} id="speciality_cost" onChange={changeKettle} name="speciality_cost" placeholder="Speciality Cost (Excluding GST)" required />
                                </div>
                            </div>

                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="Subscription_plan">Subscription Plan</label>
                                    <Dropdown id="Subscription" name="subscription_type" value={selectedSubOption} onChange={changeKettle} options={subScriptionOptions} optionLabel="name" placeholder="Subscription Plan" filter className="multiselect-custom" required />
                                </div>
                            </div>

                            {
                                selectedSubOption?.code == 'RENT' &&
                                <>
                                    <div className="p-col-4">
                                        <div className="p-field">
                                            <label htmlFor="rental_plan">Maintenance Plan</label>
                                            <Dropdown id="rental_plan" name="rental_plan" value={selectedRentleOption} onChange={changeKettle} options={rentleOptions} optionLabel="name" placeholder="Maintenance Plan" filter className="multiselect-custom" required />
                                        </div>
                                    </div>
                                    {/* disabled={isFacSelected && selectedRentleOption} */}
                                    <div className="p-col-4">
                                        <div className="p-field">
                                            <label htmlFor="rent_amount">Maintenance Charge (INR)</label>
                                            <InputText id="rent_amount" type="number" name="rent_amount" placeholder="Maintenance Charge" min={0} max={99999} onChange={changeKettle} required />
                                        </div>
                                    </div>
                                </>
                            }

                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="refundable_deposit">Refundable Security Deposit (INR)</label>
                                    <InputText id="refundable_deposit" type="number" name="refundable_deposit" placeholder="Security Deposit" defaultValue={0} min={0} step={1} max={99999} onChange={changeKettle} required />
                                </div>
                            </div>

                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="typeOfEntity">Type of Entity</label>
                                    <Dropdown id="typeOfEntity" name="legal_entity_name" value={selectedTypeOfEntity} onChange={changeKettle} options={entityOptions} optionLabel="name" placeholder="Type of Entity" filter className="multiselect-custom" required />
                                </div>
                            </div>
                        </div>


                        <hr></hr>
                        <h5>PAYMENT TERMS</h5>
                        <div className="p-grid">
                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="mode">Payment Mode</label>
                                    <Dropdown id="mode" name="mode" value={selectedMode} onChange={changeKettle} options={paymentTermOptions} optionLabel="name" placeholder="Select Mode" filter className="multiselect-custom" required />
                                </div>
                            </div>

                            {
                                (selectedMode?.code == "PREPAID" || selectedMode?.code == "PARTIAL") &&
                                <>
                                    <div className="p-col-4">
                                        <div className="p-field">
                                            <label htmlFor="prepaid_payment_type">Payment Type</label>
                                            <Dropdown id="prepaid_payment_type" name="prepaid_payment_type" value={selectedPaymentType} onChange={changeKettle} options={paymentTypeOptions} optionLabel="name" placeholder="Select Mode" filter className="multiselect-custom" />
                                        </div>
                                    </div>
                                </>
                            }
                            {/* disabled={isFacSelected && selectedPaymentType}  */}
                            {
                                (selectedMode?.code == "POSTPAID" || selectedMode?.code == "PARTIAL") &&
                                <div className="p-col-4">
                                    <div className="p-field">
                                        <label htmlFor="extPeriod">Extension Period</label>

                                        <Dropdown id="extPeriod" name="extPeriod" value={seletedExtPeriod} onChange={changeKettle} options={extentionPeriodOptions} optionLabel="value" placeholder="Extension Period" filter className="multiselect-custom" />

                                    </div>
                                </div>
                            }
                            {/* disabled={isFacSelected && defaultUser?.ctvm_payment_terms?.extPeriod} */}
                            {
                                selectedMode?.code == "PARTIAL" &&
                                <div className="p-col-4">
                                    <div className="p-field">
                                        <label htmlFor="minAmtPercent">Min Amt Percent</label>
                                        <InputText type="number" id="minAmtPercent" name="minAmtPercent" placeholder="Min Amt Percent" min={1} max={99} onChange={changeKettle} />
                                    </div>
                                </div>
                            }
                        </div>


                        <hr></hr>


                        <h5>SIGNING AUTHORITY INFORMATION <small> (Same as Personal <Checkbox onChange={(e) => { changeSigned(e) }} checked={signingCheck}></Checkbox>)</small> </h5>
                        <div className="p-grid">
                            <div className="p-col-2">
                                <div className="p-field">
                                    <label htmlFor="prefix">Prefix</label>
                                    <Dropdown id="prefix" name="signing_authority_prefix" value={selectedPrefixForSign} onChange={changeKettle} options={prefixOptions} optionLabel="name" placeholder="Prefix" className="multiselect-custom" required />
                                </div>
                            </div>
                            <div className="p-col-5">
                                <div className="p-field">
                                    <label htmlFor="auth_name">First Name</label>
                                    <InputText id="auth_name" name="signing_authority_person_name" placeholder="First Name" onChange={changeKettle} value={proceedPayload?.signing_authority_person_name ? proceedPayload?.signing_authority_person_name : ''} required />
                                </div>
                            </div>
                            <div className="p-col-5">
                                <div className="p-field">
                                    <label htmlFor="auth_name">Last Name</label>
                                    <InputText id="auth_name" name="signing_authority_person_last_name" placeholder="Last Name" onChange={changeKettle} value={proceedPayload?.signing_authority_person_last_name ? proceedPayload?.signing_authority_person_last_name : ''} required />
                                </div>
                            </div>
                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="designation">Designation</label>
                                    <InputText id="designation" name="signing_authority_designation" placeholder="Designation" onChange={changeKettle} required />
                                </div>
                            </div>
                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="signing_authority_mobile">Mobile Number</label>
                                    <InputText id="signing_authority_mobile" name="signing_authority_mobile" placeholder="Mobile Number" 
                                    value={proceedPayload?.signing_authority_mobile ? proceedPayload?.signing_authority_mobile : ''}
                                    minLength={10} maxLength={10} onChange={changeKettle} required />
                                </div>
                            </div>
                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="email">Email Id</label>
                                    <InputText id="email" type="email" name="signing_authority_email" placeholder="Email Id" onChange={changeKettle} value={proceedPayload?.signing_authority_email} required />
                                </div>
                            </div>
                        </div>

                        <h5>INSTALLATION CHECKLIST</h5>
                        <div className="flex justify-content-center">
                            <div className="flex flex-column gap-3">
                                <div className="flex align-items-center">
                                    <Checkbox className="p-mt-4" name="is_power" checked={proceedPayload?.is_power} onChange={changeKettle} />
                                    <label className="p-ml-2">16A Power Plug & Supply available</label>
                                </div>
                                <div className="flex align-items-center">
                                    <Checkbox className="p-mt-4" name="is_space" checked={proceedPayload?.is_space} onChange={changeKettle} />
                                    <label className="p-ml-2">Space for Tapri Kettle (10 in * 10 in) available</label>
                                </div>
                                <div className="flex align-items-center">
                                    <Checkbox className="p-mt-4" name="is_water" checked={proceedPayload?.is_water} onChange={changeKettle} />
                                    <label className="p-ml-2">20L water bottle available</label>
                                </div>
                                <div className="flex align-items-center">
                                    <Checkbox className="p-mt-4" name="is_earthing" checked={proceedPayload?.is_earthing} onChange={changeKettle} />
                                    <label className="p-ml-2">Earthing available</label>
                                </div>
                            </div>
                        </div>

                        <h6>AADHAR CARD </h6>
                        <div className="p-grid">
                            <div className="p-col-6">
                                <div className="p-field">
                                    {/* <label htmlFor="adhf">Aadhar Card (Front)</label> */}
                                    <InputText id="adhf" name="proof_front" type="file" accept="image/x-png,image/gif,image/jpeg" onChange={changeFiles} style={{ display: 'none' }} required />
                                </div>
                            </div>
                            <div className="p-col-6">
                                <div className="p-field">
                                    {/* <label htmlFor="adhb">Aadhar Card (back)</label> */}
                                    <InputText id="adhb" name="proof_back" type="file" accept="image/x-png,image/gif,image/jpeg" onChange={changeFiles} style={{ display: 'none' }} required />
                                </div>
                            </div>

                            <div className="p-col-6">
                                <label htmlFor="adhf">
                                    <img id="addhar-Front" src={'assets/demo/images/user/id-front.png'} alt="Aadhar Card (Front)" style={{ height: '230px', width: '100%' }} />
                                </label>
                            </div>

                            <div className="p-col-6">
                                <label htmlFor="adhb">
                                    <img id="addhar-back" src={'assets/demo/images/user/id-back.png'} alt="Aadhar Card (Back)" style={{ height: '230px', width: '100%' }} />
                                </label>
                            </div>

                        </div>

                        <h6>STORE PHOTO </h6>
                        <div className="p-grid">
                            <div className="p-col-7">
                                <div className="p-field">
                                    {/* <label>Store Photo</label> */}
                                    <InputText id="storePhoto" name="proof_store" type="file" accept="image/x-png,image/gif,image/jpeg" onChange={changeFiles} style={{ display: 'none' }} required />
                                </div>
                            </div>
                            <div className="p-col-7">
                                <label htmlFor="storePhoto">
                                    <img id="store-photo" src={'assets/demo/images/user/default-store.png'} alt="Store-Img" style={{ height: '230px', width: '100%' }} />
                                </label>
                            </div>
                        </div>

                    </form>
                </Dialog>
            }

            {
                isRegistered && <KettleProducts setIsRegistered={setIsRegistered} registeredUser={registeredUser} latLong={props.latLong} setIsProceeded={props.setIsProceeded} />
            }

        </>
    )
}